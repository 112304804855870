import '@/assets/style/main.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import favicon from './favicon.ico'

// 导入markdown线管组件
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// 导入Element-UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false

Vue.use(mavonEditor)
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
