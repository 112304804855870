const Index = () => import('@/views/Index.vue')
const OldIndex = () => import('@/views/OldIndex.vue')
const BlogDetail = () => import('@/views/blog/BlogDetail')
const BlogAdd = () => import('@/views/blog/BlogAdd')
const BlogEdit = () => import('@/views/blog/BlogEdit')
const Login = () => import('@/views/user/Login.vue')
const AdminLayout = () => import('@/views/layout/AdminLayout.vue')
const User = () => import('@/views/admin/User.vue')
const BlogType = () => import('@/views/admin/BlogType.vue')
const Blog = () => import('@/views/admin/Blog.vue')

const routes = [
  // 客户端页面
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  // {
  //   path: '/indexTest',
  //   name: 'IndexTest',
  //   component: IndexTest
  // },
  // {
  //   path: '/markdownDemo',
  //   name: 'MarkDownDemo',
  //   component: MarkDownDemo
  // },
  // {
  //   path: '/markMapDemo',
  //   name: 'MarkMapDemo',
  //   component: MarkMapDemo
  // },
  {
    path: '/old/index',
    name: 'OldIndex',
    component: OldIndex
  },
  {
    path: '/blog/detail',
    name: 'BlogDetail',
    component: BlogDetail
  },
  {
    path: '/blog/add',
    name: 'BlogAdd',
    component: BlogAdd
  },
  {
    path: '/blog/edit',
    name: 'BlogEdit',
    component: BlogEdit
  },
  // 后端管理页
  {
    path: '/admin',
    redirect: '/admin/user'
  },
  {
    path: '/admin',
    name: '后端首页',
    component: AdminLayout,
    meta: {
      title: '博客管理系统',
      role: ['root']
    },
    children: [
      {
        path: '/admin/user',
        name: '用户管理',
        component: User,
        meta: {
          title: '用户管理',
          role: ['root']
        }
      }, {
        path: '/admin/blogType',
        name: '博客类型管理',
        component: BlogType,
        meta: {
          title: '博客类型管理',
          role: ['root']
        }
      }, {
        path: '/admin/blog',
        name: '博客管理',
        component: Blog,
        meta: {
          title: '博客管理',
          role: ['root']
        }
      }
    ]
  }
]

export default routes
