import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/config/routeConfig'
import config from '@/config/config.json'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

const tokenField = config.tokenField
const userInfoField = config.userInfoField

// 前置导航守卫，进入页面前先校验
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    // 其他页面都必须要有token才可以访问
    const localUserInfo = localStorage.getItem(userInfoField)
    if (localUserInfo !== null) {
      const userInfo = JSON.parse(localUserInfo)
      const current = new Date().getTime()
      if (userInfo !== null && Object.hasOwnProperty.call(userInfo, 'loginExpireTime') && userInfo.loginExpireTime >= current) {
      // if (userInfo !== null) {
        next()
      } else {
        localStorage.setItem('lastPage', to.path)
        next('/login')
        localStorage.removeItem(tokenField)
        localStorage.removeItem(userInfoField)
      }
    } else {
      localStorage.setItem('lastPage', to.path)
      next('/login')
      localStorage.removeItem(tokenField)
      localStorage.removeItem(userInfoField)
    }
  }
})

export default router
