<template>
  <div>
    <head>
      <link rel="icon" href="/favicon.ico" type="image/x-icon">
      <!-- 其他头部信息 -->
    </head>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app{
  margin-top: 0 px;
}
body{
  margin: 0;
  padding: 0;
  border: 0
}
::-webkit-scrollbar {
/*滚动条整体样式*/
  width : 8px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 5px;
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  background   : #cacaca;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  background   : #ededed;
}
</style>
